<template>
  <div class="container">
    <div v-if="enterpriseData.enterpriseType === 'controlled'" class="container-header mb-10">
      <div class="header-title">{{ $route.params.name }}</div>
      <a-button size="default" icon="double-left" @click="toLink" class="mr-10">返回列表</a-button>
    </div>
    <div class="container-content">
      <div class="left pr-10">
        <h3>应用包</h3>
        <a-list :data-source="packageList">
          <a-list-item slot="renderItem" slot-scope="item" class="package-list pl-10" :class="selectPackage.applicationPackageId === item.applicationPackageId ? 'active' : ''" @click="onclick(item)">
            <span class="package-list-name">{{ item.applicationPackageName }}</span>
          </a-list-item>
        </a-list>
      </div>
      <div class="right pl-10">
        <div class="right-header mb-10">
          <a-input v-model="applicationName" allowClear placeholder="应用名" class="w-200 mr-10" />
          <a-input v-model="applicationKey" allowClear placeholder="应用key" class="w-200 mr-10" />
          <a-button type="primary" :loading="btnLoading" @click="serach">查询</a-button>
        </div>
        <div class="right-content">
          <a-table
            :columns="columns"
            :data-source="applicationList"
            :pagination="false"
            :defaultExpandAllRows="true"
            childrenColumnName="children"
            :loading="loading"
            :rowKey="(record) => (record.applicationKey || record.applicationGroupId)"
            :scroll="{ x: '100%', y: 'calc(100vh - 292px)' }"
            :customRow="(record) => {
              return {
                class: record.isGroup ? 'group-row' : ''
              }
            }"
          >
            <!--:row-selection="rowSelection"-->
            <span slot="customTitle">名称</span>
            <span slot="applicationType" slot-scope="text" >{{ typeList[text] }}</span>
            <span slot="isGroup" slot-scope="text" >{{ text ? '应用组' : '应用' }}</span>
            <span slot="permissionStatus" slot-scope="text, record"  v-if="!record.isGroup">{{ text ? '指定成员可见' : '全部可见' }}</span>
            <span slot="creatorName" slot-scope="text, record" v-if="!record.isGroup" >{{ text }}</span>
            <span slot="created" slot-scope="text, record" v-if="!record.isGroup" >{{ text }}</span>
            <span slot="isEnable" slot-scope="text, record"  v-if="!record.isGroup">
              <a-tag :color="text? 'green' : 'volcano'">{{ text ? '启用' : '禁用'}}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record" v-if="!text.isGroup">
              <a-button v-if="!record.isEnable" type="link" @click="changeEnable(true, record.applicationKey)">启用</a-button>
              <a-button v-if="record.isEnable" type="link" @click="changeEnable(false, record.applicationKey)">禁用</a-button>
            </span>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  applicationPackageList,
  applicationTree,
  applicationStatusModify
} from '@/api/application'
const columns = [
  {
    // title: '名称',
    dataIndex: 'title',
    key: 'title',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'title' },
    ellipsis: true,
    // width: 150
  },
  {
    title: 'key',
    dataIndex: 'applicationKey',
    key: 'applicationKey',
    ellipsis: true,
    // width: 150
  },
  {
    title: '类型',
    dataIndex: 'isGroup',
    key: 'isGroup',
    // width: 80,
    scopedSlots: { customRender: 'isGroup' },
  },
  {
    title: '创建人',
    dataIndex: 'creatorName',
    key: 'creatorName',
    // width: 150,
    scopedSlots: { customRender: 'creatorName' },
    ellipsis: true
  },
  {
    title: '可见范围',
    dataIndex: 'permissionStatus',
    key: 'permissionStatus',
    // width: 120,
    scopedSlots: { customRender: 'permissionStatus' },
  },
  {
    title: '状态',
    dataIndex: 'isEnable',
    key: 'isEnable',
    // width: 60,
    scopedSlots: { customRender: 'isEnable' },
  },
  {
    title: '创建时间',
    dataIndex: 'created',
    key: 'created',
    // width: 180,
    scopedSlots: { customRender: 'created' },
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    customCell: () => {
      return {
        style:{
          padding: '0'
        }
      }
    },
    width: 100
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
  getCheckboxProps: record => ({
    props: {
      disabled: record.isGroup, // Column configuration not to be checked
      name: record.title
    },
  }),
};
export default {
  name: 'AdhibitionList',
  props:{
    enterpriseData: {
      type: Object,
      default:() => {
        return {
          enterpriseId: '',
        }
      }
    }
  },
  data() {
    return {
      applicationList: [],
      columns,
      rowSelection,
      packageList: [],
      loading: false,
      btnLoading: false,
      selectPackage: {},
      selectId: '',
      typeList: { 0: '未知类型', 1: '普通应用', 2: '视图应用' },
      applicationName: '',
      applicationKey: ''
    };
  },
  created() {
    this.getApplicationPackageList(true)
  },
  methods: {
    // 返回列表
    toLink() {
      this.$router.push({
        name: 'EnterpriseList'
      })
    },
    // 应用包列表
    getApplicationPackageList(isInitial) {
      applicationPackageList({ enterpriseId: this.enterpriseData.enterpriseId }).then((res) => {
        if (res['code' !== 0]) {
          this.$antMessage.error(res['message'])
        }
        this.packageList = res.data
        // this.packageList = packageData
        if (isInitial && this.packageList && this.packageList.length > 0) {
          this.selectPackage = this.packageList[0]
          this.getApplicationPackageInfo()
        }
      })
    },
    // 应用树
    getApplicationPackageInfo() {
      this.loading = true
      // const data = new FormData()
      // data.append('applicationPackageId', this.selectPackage.applicationPackageId)
      const data = {
        applicationPackageId: this.selectPackage.applicationPackageId,
        applicationName: this.applicationName,
        applicationKey: this.applicationKey,
      }

      applicationTree(data).then((res) => {
        this.loading = false
        if (res['code' !== 0]) {
          this.$antMessage.error(res['message'])
        }
        this.applicationList = res.data
        // this.applicationList = applicationData
      })
    },
    onclick(item) {
      this.selectId = item.applicationPackageId
      this.selectPackage = item
      this.getApplicationPackageInfo()
    },
    // 搜索
    serach() {
      this.getApplicationPackageInfo()
    },
    // 启用/禁用
    changeEnable(bol, applicationKey) {
      // if (bol) {
      //   console.log('启用')
      // } else {
      //   console.log('禁用')
      // }
      const text = bol ? '启用' : '禁用'
      const that = this
      this.$confirm({
        content: '是否' + text + '该应用？',
        onOk() {
          const data = {
            applicationKey: applicationKey,
            isEnable: bol ? 1 : 0
          }
          applicationStatusModify(data).then((res) => {
            if (res['code'] !== 0) {
              that.$antMessage.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            that.getApplicationPackageInfo()
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  //display: flex;
  .container-header{
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-title{
      font-size: 20px;
    }
  }
  .container-content{
    display: flex;
    .left {
      width: 20%;
      min-width: 200px;
      border-right: 1px solid #e8e8e8;
      overflow-y: auto;
      ::v-deep .ant-list{
        .package-list{
          &:hover{
            background-color: #e6f7ff;
          }
          &.active{
            background-color: #1890ff;
            color: #fff;
          }
          .package-list-name{
            display: block;
            width: 270px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }
        }
      }
    }
    .right{
      width: 80%;
      //width: calc(100% - 300px);
      .right-header{
        display: flex;
        justify-content: flex-start;
      }
    }
    .left,.right{
      height: calc(100vh - 194px);
      &::-webkit-scrollbar{
        width: 8px;
        height: 8px;
      }
    }
  }
}
::v-deep .ant-table{
  .ant-table-scroll{
    & >.ant-table-header.ant-table-hide-scrollbar{
      //border: 1px solid #e8e8e8;
    }
    & > .ant-table-body{
      //min-height: calc(100vh - 292px);
      //border: 1px solid #e8e8e8;
      border-top: none;
    }
  }
  .group-row{
    background-color: #f5f5f5;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    //width: 100px;
  }
}
.w-200{
  width: 200px;
}
</style>
