<template>
  <AdhibitionList :enterpriseData="enterpriseData"></AdhibitionList>
</template>

<script>
import AdhibitionList from '@/components/adhibition/adhibitionList'
export default {
  name: 'EnterpriseAdhibitionList',
  data() {
    return{
      enterpriseData: {
        enterpriseId: this.$route.params.id,
        enterpriseName: this.$route.params.name,
        enterpriseType: 'controlled'
      }
    }
  },
  components:{
    AdhibitionList
  }
}
</script>

<style scoped>

</style>
