import { render, staticRenderFns } from "./adhibitionList.vue?vue&type=template&id=4eef7f98&scoped=true&"
import script from "./adhibitionList.vue?vue&type=script&lang=js&"
export * from "./adhibitionList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eef7f98",
  null
  
)

export default component.exports