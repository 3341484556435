import axios from '@/utils/request'

/**
 * 应用包列表
 * @data {*} data
 */
export function applicationPackageList(data) {
  return axios({
    url: '/application/package/list',
    method: 'post',
    data
  })
}

/**
 * 重新排序应用包
 * @data {*} data
 */
export function applicationPackageReorder(data) {
  return axios({
    url: '/application/package/reorder',
    method: 'post',
    data
  })
}

/**
 * 应用包详情
 * @data {*} data
 */
export function applicationPackageInfo(data) {
  return axios({
    url: '/application/package/info',
    method: 'post',
    data
  })
}

/**
 * 应用包基础详情
 * @data {*} data
 */
export function applicationPackageBaseInfo(data) {
  return axios({
    url: '/application/package/base-info',
    method: 'post',
    data
  })
}

/**
 * 重新排序应用包应用
 * @data {*} data
 */
export function applicationPackageItemReorder(data) {
  return axios({
    url: '/application/package/item/reorder',
    method: 'post',
    data
  })
}

/**
 * 应用列表
 * @data {*} data
 */
export function applicationList(data) {
  return axios({
    url: '/application/list',
    method: 'post',
    data
  })
}

/**
 * 应用信息
 * @data {*} data
 */
export function applicationInfo(data) {
  return axios({
    url: '/application/info',
    method: 'post',
    data
  })
}

/**
 * 应用基础信息
 * @data {*} data
 */
export function applicationBaseInfo(data) {
  return axios({
    url: '/application/base-info',
    method: 'post',
    data
  })
}

/**
 * 应用基础信息
 * @data {*} data
 */
export function applicationStatusModify(data) {
  return axios({
    url: '/application/status/modify',
    method: 'post',
    data
  })
}

/**
 * 应用基础信息
 * @data {*} data
 */
export function applicationTree(data) {
  return axios({
    url: '/application/tree',
    method: 'post',
    data
  })
}

